<template>
  <div class="about-us">
    <main
      class="relative min-h-screen flex flex-col justify-center overflow-hidden"
    >
      <div class="w-full mx-auto px-4 md:px-6 py-8 my-8">
        <div class="w-full max-w-4xl mx-auto mb-4">
          <h1 class="font-black text-main-purple-500 text-6xl text-center my-4">
            Nossas Lojas
          </h1>
          <h3
            class="font-regular text-main-pink-500 text-2xl text-center leading-6"
          >
            Encontre a unidade da Nova mais próxima de você!
          </h3>
        </div>
        <div class="flex flex-col justify-center">
          <div class="w-full max-w-4xl mx-auto">
            <our-stores-box
              store-title="RIBEIRÃO DAS NEVES - MATRIZ"
              store-address="Rua Etelvina Maria de Souza, 242 - Nossa Senhora das Neves, Ribeirão das Neves/MG - 33805-030"
              opening-hours="08:00 às 18:00"
            />
            <our-stores-box
              store-title="RIBEIRÃO DAS NEVES - FILIAL"
              store-address="Rua Helena Sapori Faluba, 85 - Veneza, Ribeirão das Neves/MG - 33820-170"
              opening-hours="08:00 às 17:00"
            />
            <our-stores-box
              store-title="SANTA LUZIA - FILIAL"
              store-address="Rua Rio das Velhas, 19 - São João Batista - Santa Luzia/MG - 33030-030"
              opening-hours="08:00 às 17:00"
            />
            <our-stores-box
              store-title="ESMERALDAS - FILIAL"
              store-address="Rua Fortaleza, 14 - Santa Cecilia - Esmeraldas/MG - 35740-000"
              opening-hours="08:00 às 17:00"
            />
            <our-stores-box
              store-title="VESPASIANO - FILIAL"
              store-address="Av. Existente, 1046 - Conj. Morro Alto - Vespasiano/MG - 33200-000"
              opening-hours="08:00 às 17:00"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import OurStoresBox from "@/components/layout/goods/OurStoresBox.vue";
export default {
  name: "App",
  components: {
    OurStoresBox,
  },
};
</script>

FaqAccordion
